import { ThemeOptions, alpha } from '@mui/material'
import '@fontsource/open-sans'

// TODO: move this to another file later
export const defaultTheme: ThemeOptions = {
  shape: {
    borderRadius: 5,
  },
  spacing: 10,
  palette: {
    primary: {
      light: '#0082E4',
      main: '#006CB0',
      dark: '#006CB0',
      primaryColor500: '#3B1968',
      primaryColor400: '#502D7F',
      primaryColor300: '#754DAA',
      primaryColor200: '#D5B5FF',
      primaryColor100: '#DEDCFF',
      primaryColor50: '#E8EEFF',
      contrastText: '#fff',
    },
    success: {
      main: '#5eb95e',
    },
    secondary: {
      light: '#fff',
      main: '#9DD5FF',
      dark: '#9DD5FF',
      contrastText: '#006CB0',
    },
    error: {
      light: '#fff',
      main: '#DA4760',
      dark: '#DA4760',
      contrastText: '#DA4760',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#3F3F3F',
      secondary: '#0075CE',
      disabled: '#8F8F8F',
    },
    info: {
      light: '#F2F2F2',
      main: '#dce2e8',
      dark: '#C2C2C2',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '36px',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          // Targeting the scrollbar for Webkit browsers
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          maxHeight: '40px',
          maxWidth: '40px',
          margin: '0',
          display: 'flex',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '30px',
          borderRadius: 5,
          padding: '5px 10px',
          fontWeight: 400,
          '&.button': {
            border: '1px solid',
          },
          '&.primaryButton': {
            color: '#fff',
            borderColor: '#0082E4',
            backgroundColor: '#0082E4',
            '&:hover': {
              borderColor: '#006CB0',
              backgroundColor: '#006CB0',
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
          '&.secondaryButton': {
            color: '#006CB0',
            borderColor: '#006CB0',
            backgroundColor: '#fff',
            '&:hover': {
              color: '#006CB0',
              borderColor: '#006CB0',
              backgroundColor: '#9DD5FF',
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
          '&.tertiaryButton': {
            color: '#DA4760',
            borderColor: '#DA4760',
            backgroundColor: '#fff',
            '&:hover': {
              color: '#DA4760',
              borderColor: '#DA4760',
              backgroundColor: alpha('#DA4760', 0.3),
            },
            '&:disabled': {
              backgroundColor: '#fff',
              borderColor: '#8F8F8F',
              color: '#8F8F8F',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
        root: {
          // Targeting the scrollbar for Webkit browsers
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000000',
          fontSize: '0.8125rem',
          fontWeight: 400,
          borderRadius: 5,
        },
        arrow: {
          color: '#000000',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: '#8F8F8F',
        },
        noOptions: {
          color: '#8F8F8F',
          fontSize: '13px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeightBold: 600,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    fontWeightLight: 400,
    fontSize: 13,
    h1: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '0.8125rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.8125rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.8125rem',
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: '0.8125rem',
    },
    overline: {
      fontSize: '0.8125rem',
      textTransform: 'uppercase',
    },
  },
}
