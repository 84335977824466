import { FC, PropsWithChildren, MouseEvent, useMemo } from 'react'
import {
  Box,
  Stack,
  ButtonProps,
  debounce,
  IconButton,
  Theme,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'
import {
  StyledDialog,
  StyledDialogContainer,
  StyledDialogFooter,
  StyledDialogButton,
} from './dialog.styles'
import { ContentLoader } from '../Loader/ContentLoader'

type DialogProps = {
  open: boolean
  onClose?: () => void
  title?: string | JSX.Element
  primaryButton?: boolean
  primaryButtonProps?: ButtonProps
  secondaryButton?: boolean
  secondaryButtonProps?: ButtonProps
  tertiaryButton?: boolean
  tertiaryButtonProps?: ButtonProps
  hideCloseButton?: boolean
  loading?: boolean
  paperSx?: SxProps<Theme>
  allowBackdropClickClose?: boolean
  allowEscapeKeyClose?: boolean
  error?: string
  warning?: string
  width?: number
}

const CustomDialog: FC<PropsWithChildren<DialogProps>> = (
  props: PropsWithChildren<DialogProps>
) => {
  const {
    children,
    title,
    open,
    onClose,
    primaryButton,
    primaryButtonProps,
    secondaryButton,
    secondaryButtonProps,
    tertiaryButton,
    tertiaryButtonProps,
    hideCloseButton,
    loading,
    paperSx,
    allowBackdropClickClose,
    allowEscapeKeyClose,
    error,
    warning,
    width,
  } = props

  const handlePrimaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    primaryButtonProps?.onClick?.(event)
  }

  const handleSecondaryClick = (event: MouseEvent<HTMLButtonElement>) => {
    secondaryButtonProps?.onClick?.(event)
  }

  const handleDialogClose = (
    _event: unknown,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' && !allowBackdropClickClose) return false
    if (reason === 'escapeKeyDown' && !allowEscapeKeyClose) return false
    onClose?.()
  }

  const debouncedPrimaryBtnClick = useMemo(
    () => debounce(handlePrimaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [primaryButtonProps?.onClick]
  )

  const debouncedSecondaryBtnClick = useMemo(
    () => debounce(handleSecondaryClick, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [secondaryButtonProps?.onClick]
  )

  return (
    <StyledDialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby='dialog'
      PaperProps={{
        sx: {
          width: width ?? 424,
          ...paperSx,
          minHeight: '200px',
          '&::-webkit-scrollbar': {
            display: 'none !important',
          },
          //hide the scroll bar
          '-ms-overflow-style': 'none !important',
          //hide scrollbar
        },
      }}
    >
      <ContentLoader loading={loading ?? false} />
      <StyledDialogContainer>
        <Box>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              // pb: 2,
              // mt: 2,
              position: 'sticky',
              top: 0,
              alignItems: 'center',
              backgroundColor: theme => theme.palette.primary.primaryColor500,
              color: theme => theme.palette.background.default,
              fontWeight: 600,
              height: '41px !important',
              zIndex: 100,
              ml: 0,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <Box>
              {title && typeof title === 'string' && (
                <Box>
                  <Typography variant='h3'>{title}</Typography>
                </Box>
              )}
              {title && typeof title !== 'string' && title}
            </Box>
            {!hideCloseButton && (
              <Box>
                <IconButton
                  color='primary'
                  onClick={() => onClose?.()}
                  sx={{ padding: 0 }}
                >
                  <CloseIcon
                    sx={{ fill: theme => theme.palette.background.default }}
                  />
                </IconButton>
              </Box>
            )}
          </Stack>
          <Box sx={{ pt: 1, pl: 2, pr: 2 }}>
            <Stack
              direction='column'
              justifyContent='space-between'
              sx={{ pb: 1, height: '100%' }}
            >
              {children}
              {error && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'red',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={13}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
              {warning && (
                <Box>
                  <Typography
                    sx={{
                      width: '100%',
                      color: 'orange',
                      marginTop: '4px',
                      paddingLeft: '4px',
                    }}
                    fontSize={13}
                  >
                    {warning}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
        {(primaryButtonProps ||
          secondaryButtonProps ||
          tertiaryButtonProps) && (
          <StyledDialogFooter
            justifyContent='center'
            sx={{
              pl: 2,
              pr: 2,
              pt: 1.5,
              pb: 1.5,
              borderTop: '1px solid #C2C2C2',
            }}
          >
            {tertiaryButtonProps || tertiaryButton ? (
              <StyledDialogButton
                className='button tertiaryButton'
                variant='text'
                color='primary'
                {...tertiaryButtonProps}
                onClick={tertiaryButtonProps?.onClick}
              >
                {tertiaryButtonProps?.children ?? 'Delete'}
              </StyledDialogButton>
            ) : (
              <Box />
            )}
            <Box display='flex' gap={1}>
              {(secondaryButtonProps || secondaryButton) && (
                <StyledDialogButton
                  className='button secondaryButton'
                  variant='text'
                  color='primary'
                  {...secondaryButtonProps}
                  onClick={debouncedSecondaryBtnClick}
                  sx={{
                    ml: 1,
                    pl: 2,
                    pr: 2,
                    border: '1px solid',
                    borderRadius: '5px',
                    borderColor: theme => theme.palette.primary.primaryColor400,
                    '&:hover': {
                      color: '#ffffff',
                      backgroundColor: theme =>
                        theme.palette.primary.primaryColor400,
                    },
                  }}
                >
                  {secondaryButtonProps?.children ?? 'No'}
                </StyledDialogButton>
              )}
              {(primaryButtonProps || primaryButton) && (
                <StyledDialogButton
                  className='button primaryButton'
                  variant='text'
                  color='primary'
                  {...primaryButtonProps}
                  onClick={e => {
                    if (!error) {
                      debouncedPrimaryBtnClick(e)
                    }
                  }}
                >
                  {primaryButtonProps?.children ?? 'Yes'}
                </StyledDialogButton>
              )}
            </Box>
          </StyledDialogFooter>
        )}
      </StyledDialogContainer>
    </StyledDialog>
  )
}

export default CustomDialog
