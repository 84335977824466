import { useState } from 'react'
import { Folder } from '../../../../models'
import {
  Button,
  Collapse,
  Icon,
  Stack,
  Tooltip,
  Typography,
  Theme,
} from '@mui/material'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../../create-custom-report/custom-report-table/strict-mode-dropable'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ManageFolderDialog from '../manage-folder-dialog'
import { SingleFolderItem } from './folder-item'
import { ReactComponent as TooltipSVG } from '../../../../assets/tooltipIcon.svg'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleFolderStyling: {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.primary.primaryColor400,
      //minWidth: '360px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      border: '1px solid',
      borderColor: theme.palette.info.light,
      height: '100%',
      maxHeight: '262px',
      overflow: 'hidden',
      maxWidth: theme.spacing(46.5),
      minWidth: theme.spacing(46.5),
      //make scroll bar skinny
    },
    folderContainer: {
      padding: 0,
      width: '100%',
      top: 0,
      backgroundColor: theme.palette.primary.primaryColor100,
      height: '33px',
    },
    editButton: {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.primary.dark,
    },
  })
)

export function SingleFolder(props: {
  folder: Folder
  numFolders: number
  userFolders: Folder[]
  createFolder: (folder: any) => void
  updateFolder: (folder: any) => void
  deleteFolder: (folder: any) => void
  isLoading?: boolean
}) {
  const {
    folder,
    numFolders,
    userFolders,
    createFolder,
    updateFolder,
    deleteFolder,
    isLoading,
  } = props
  const { name, items, id, disableEdit, description } = folder
  const classes = useStyles()

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return
    const sortableItems = Array.from(items)
    const [reorderedItem] = sortableItems.splice(result.source.index, 1)
    sortableItems.splice(result.destination.index, 0, reorderedItem)
    await updateFolder({ items: sortableItems, folderId: id })
  }

  const onRemoveFolderItem = async (itemId: string) => {
    const newItems = items.filter(item => item.id !== itemId)
    await updateFolder({ items: newItems, folderId: id })
  }

  const [isExpanded, setIsExpanded] = useState(true) //items?.length > 0)
  const [openFolderSettings, setOpenFolderSettings] = useState(false)

  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  if (folder.isFavorites && folder.items?.length === 0) {
    return null
  }
  const folderDescriptionChecker =
    description === undefined || description.trim().length === 0 ? false : true

  return (
    <Stack
      direction={'column'}
      className={classes.singleFolderStyling}
      sx={{
        width: `calc(100% / ${folderSpacing} - ${
          folderSpacing === 1 ? 0 : 32
        }px)`,
        '&:hover': {
          backgroundColor: !isExpanded && '#dce2e8',
        },
      }}
    >
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          p: 0,
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={1}
          className={classes.folderContainer}
          sx={{
            position: 'sticky !important',
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1}
            overflow={'hidden'}
          >
            <Icon
              sx={{
                cursor: 'pointer',
                ml: 1,
                color: theme => theme.palette.primary.primaryColor400,
              }}
            >
              {isExpanded ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </Icon>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                color: theme => theme.palette.primary.primaryColor400,
              }}
              noWrap
            >
              {name}
            </Typography>
            {folderDescriptionChecker ? (
              <Tooltip
                title={description}
                sx={{
                  color: '#000000',
                  width: '16px',
                }}
                arrow={true}
                placement='top'
              >
                <TooltipSVG />
              </Tooltip>
            ) : undefined}
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              paddingRight: theme => theme.spacing(1),
            }}
          >
            {!disableEdit &&
              folder.isOwnedByUser &&
              folder.name !== 'Favorites' && (
                <Stack
                  onClick={e => {
                    e.stopPropagation()
                    setOpenFolderSettings(true)
                  }}
                >
                  <Typography className={classes.editButton}>Edit</Typography>
                </Stack>
              )}
          </Stack>
        </Stack>
      </Button>
      <Collapse in={isExpanded} easing={'ease-in-out'}>
        {items?.length === 0 ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflowX: 'auto',
              height: '72px',
              fontSize: '16px',
              overflow: 'hidden',
            }}
          >
            <Typography variant='h3'>There are no saved reports</Typography>
          </Stack>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <StrictModeDroppable droppableId='droppable'>
              {provided => (
                <Stack
                  direction='column'
                  gap={0}
                  borderRadius={0}
                  sx={{
                    p: 0,
                    my: 0,
                    minWidth: '340px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id + index}
                      index={index}
                    >
                      {provided => (
                        <SingleFolderItem
                          item={item}
                          provided={provided}
                          onRemove={() => {
                            onRemoveFolderItem(item.id)
                          }}
                          disableEdit={disableEdit}
                          foldersAvailable={userFolders?.filter(
                            folder =>
                              folder.id !== id &&
                              !folder?.items?.find(
                                folderItem => folderItem.id === item.id
                              )
                          )}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}
      </Collapse>
      {openFolderSettings && (
        <ManageFolderDialog
          folder={folder}
          open={openFolderSettings}
          onClose={() => setOpenFolderSettings(false)}
          deleteFolder={deleteFolder}
          createFolder={createFolder}
          updateFolder={updateFolder}
          isLoading={isLoading}
        />
      )}
    </Stack>
  )
}
